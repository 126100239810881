import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-what-we-offer',
  templateUrl: './what-we-offer.component.html',
  styleUrls: ['./what-we-offer.component.scss', '../../../../assets/scss/static-style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WhatWeOfferComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
