import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss', '../../../../assets/scss/static-style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivacyPolicyComponent implements OnInit {


  isAppView = false;
  lang = 'en';
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isAppView = (this.route.snapshot.queryParams['appmode'] == 'true');
    this.lang = this.route.snapshot.queryParams['lang'] || 'en'

    // console.log(this.lang)
  }

}
