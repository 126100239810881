import { Component, OnInit } from '@angular/core';
import { ResponseMsgService } from '../../services/response-msg.service';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})

export class SpinnerComponent implements OnInit {

    constructor(private responseMsg: ResponseMsgService) { }

    ngOnInit() {
    }

}