import { Component, OnInit } from '@angular/core';
import { ImageModalService } from '../../services/image-modal.service'

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {

  constructor(
    private imageModalService : ImageModalService
  ) { }

  ngOnInit() {
  }

}
