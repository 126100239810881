import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/no-auth/login/login.component';
import { ForgotPasswordComponent } from './components/no-auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/no-auth/reset-password/reset-password.component';
import { LayoutComponent } from './components/layout/layout/layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { HomeComponent } from './components/web-static/home/home.component';
import { AboutUsComponent } from './components/web-static/about-us/about-us.component';
import { EnrollNowComponent } from './components/enroll-now/enroll-now.component';
import { ContactUsComponent } from './components/web-static/contact-us/contact-us.component';
import { HowWorksComponent } from './components/web-static/how-works/how-works.component';
import { PrivacyPolicyComponent } from './components/web-static/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './components/web-static/terms-of-use/terms-of-use.component';
import { FaqComponent } from './components/web-static/faq/faq.component';
import { WhatWeOfferComponent } from './components/web-static/what-we-offer/what-we-offer.component';
// import { ReportComponent } from './components/report/report.component';
// import { OrderManagementComponent } from './components/order-management/order-management.component';

const routes: Routes = [
    // { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: '', component: HomeComponent, data: {title: 'Ramblin | Home Page'}},
    { path: 'home', component: HomeComponent, data: {title: 'Ramblin | Home Page'}},
    { path: 'about-us', redirectTo: '/what-we-offer', pathMatch: 'full'},
    { path: 'what-we-offer', component: WhatWeOfferComponent, data: {title: 'Ramblin | What We Offer'}},
    { path: 'contact-us', component: ContactUsComponent, data: {title: 'Ramblin | Contact Us'} },
    { path: 'how-works', component: HowWorksComponent, data: {title: 'Ramblin | How It Works'} },
    { path: 'login', component: LoginComponent, data: {title: 'Ramblin | login' }},
	{ path: 'forgot-password', component: ForgotPasswordComponent, data: {title: 'Ramblin | Forgot Password'} },
    { path: 'reset-password/:id', component: ResetPasswordComponent, data: {title: 'Ramblin | Reset Password'} },
    { path: 'enroll-now', component: EnrollNowComponent, data: {title: 'Ramblin | Enroll Now'} },
    { path: 'privacy-policy', component: PrivacyPolicyComponent, data: {title: 'Ramblin | Privacy Policy'} },
    { path: 'terms-of-service', component: TermsOfUseComponent, data: {title: 'Ramblin | Terms Of Service Page'} },
    { path: 'faq', component: FaqComponent, data: {title: 'Ramblin | Faq'} },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children:[
            // Dashboard
            {
                path: 'dashboard',
                loadChildren: './components/dashboard/dashboard.module#DashboardModule'
            },

            // Profile
            {
                path: 'profile',
                loadChildren: './components/profile/profile.module#ProfileModule'
            },

            // Business
            {
                path: 'restaurant',
                loadChildren: './components/restaurant/restaurant.module#RestaurantModule'
            },
            // Business-type
            {
                path: 'restaurant-type',
                loadChildren: './components/restaurant/restaurant-type/restaurant-type.module#RestaurantTypeModule'
            },

            // Bartender
            {
                path: 'bartender',
                loadChildren: './components/bartender/bartender.module#BartenderModule'
            },

            // Table
            {
                path: 'table',
                loadChildren: './components/table/table.module#TableModule'
            },

            // User Managment
            {
                path: 'customer',
                loadChildren: './components/customer/customer.module#CustomerModule'
            },

            // Menu Managment
            {
                path: 'menu',
                loadChildren: './components/menu/menu.module#MenuModule'
            },

            // Item Managment
            {
                path: 'item',
                loadChildren: './components/item/item.module#ItemModule'
            },
            // order Managment
            {
                path: 'order',
                loadChildren: './components/order/order.module#OrderModule'
            },
            // report
            {
                path: 'report',
                loadChildren: './components/report/report.module#ReportModule'
            },
            // connect-stripe
            {
                path: 'connect-stripe',
                loadChildren: './components/connect-stripe/connect-stripe.module#ConnectStripeModule'
            },
            // master
            {
                path: 'master',
                loadChildren: './components/master/master.module#MasterModule'
            }
        ]
    },
    { path: '**', redirectTo: '/login' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})

export class AppRoutingModule { }