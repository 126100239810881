import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss', '../../../../assets/scss/static-style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutUsComponent implements OnInit { 
   nav = document.querySelector('#nav');
   toggleMenu = document.getElementById("toggle-nav");
   closeMenu = document.getElementById("close-nav");
   menuBar = document.querySelector('.main-menu');

   
  constructor() { }

  ngOnInit() {
  }

}
