import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-static-footer',
  templateUrl: './web-static-footer.component.html',
  styleUrls: ['./web-static-footer.component.scss']
})
export class WebStaticFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
