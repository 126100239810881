import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Constants } from 'src/app/config/constants';

@Injectable()

export class TokenInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header token if available
        let currentUser = this.authService.currentUserValue;
        // console.log("token intercepter and  currentuser")
        // console.log(currentUser)
        if (currentUser && currentUser[Constants.TOKEN]) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Token ${currentUser[Constants.TOKEN]}`
                }
            });
        }

        return next.handle(request);
    }
}