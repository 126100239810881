import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-static-header',
  templateUrl: './web-static-header.component.html',
  styleUrls: ['./web-static-header.component.scss']
})
export class WebStaticHeaderComponent implements OnInit {

  constructor() { }
  isMobilemenuOpen = false;
  ngOnInit() {
  }

  toggleMobileMenu() {
    this.isMobilemenuOpen = !this.isMobilemenuOpen
  }
  scrollToTop(){
    window.scrollTo(0, 0);
  }
}
