import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { Constants } from 'src/app/config/constants';
import { ToastrService } from 'ngx-toastr';
import { ResponseMsgService } from 'src/app/shared/services/response-msg.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {
    showResndLink : boolean = false
    hideThis : boolean = false

    forgotPassFrm: FormGroup
    constants: any

    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        private responseMsg: ResponseMsgService,
        private fb: FormBuilder,
    ) { }

    ngOnInit() {
        this.responseMsg.setCurrentClsRef(this)
        this.createForgotPassForm()
        this.constants = Constants
        this.showResndLink = false;
        this.hideThis = false
    }

    /**
     * @Description defines the form fields and validators for forgot password form using an FormBuilder
     *  to create an instance of a FormGroup and stored in forgotPassFrm property
     * 
     * @Author Avinash Jaiswal
     * */
    createForgotPassForm() {
        this.forgotPassFrm = this.fb.group({
            email : ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)]],
        })
    }

    /**
     * @Description used to access assigned loginForm.controls in short way
     * 
     * @return loginFormControls
     * 
     * @Author Avinash Jaiswal
     * */
    get forgotFrmCtrl() {
        return this.forgotPassFrm.controls
    }

    /**
     * @Description request for rest password via entered email id
     * 
     * @Author Avinash Jaiswal
     * */
    reqResetPassword() {
        console.log("form submited")
    // reqResetPassword(email: string) {
        if (this.forgotPassFrm.invalid) {
            console.log("if invalid form")
            this.toastr.error(Constants.INFO_MSG.EMAIL_REQUIRED, Constants.ERROR)
            return;
        }

        this.responseMsg.showSpinner(this)
        this.hideThis = true

        // var params = { "op": "forgot_password", "email": email, "device_type":"web" }
        var params = { "op": "forgot_password", "email": this.forgotPassFrm.value.email, "device_type":"web" }

        this.apiService.post(this, params, Constants.APIS.USERACTIVITY, false).subscribe(
            response => {
                if (response.hasOwnProperty(Constants.STATUS) && response[Constants.STATUS] == true) {
                    this.showResndLink = true
                    this.responseMsg.hideSpinner(this)
                    this.toastr.success(Constants.SUCCESS_MSG.REST_PASSWORD_LINK_SUCCESSFULLY_SEND, Constants.SUCCESS)
                    return false;
                } else {
                    var errorMessage = Constants.ERR_MSG.SOMETHING_WRONG
                    if (response.hasOwnProperty(Constants.MSG)) {
                        errorMessage = response[Constants.MSG]
                    }
                    this.toastr.error(errorMessage, Constants.ERROR)
                    this.hideThis = false
                    this.responseMsg.hideSpinner(this)
                }
            }
        );
    }
}