import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { Constants } from 'src/app/config/constants';
import { ToastrService } from 'ngx-toastr';
import { ResponseMsgService } from './response-msg.service';


@Injectable({
    providedIn: 'root'
})

export class ApiService {
    httpOptions : any
    milliSecond : 4000
    optionalParams : any
    profile: any;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private toastr: ToastrService,
        private responseMsg: ResponseMsgService
    ) {
        this.httpOptions = {
            headers : new HttpHeaders({
                'Content-Type' : 'application/json'
            })
        }

        this.optionalParams = {
            handelError : true
        }
    }

    /**
     * @Description Handel post request with observable
     * 
     * @Params params[object] and api[string]
     * @return object
     * 
     * @Author Avinash Jaiswal
     * */
    post(currenRef, params, api, handelError = true): Observable<any> {
        let postData = JSON.stringify(params)
        var url = `${Constants.API_URL}${api}/`

        return this.httpClient.post<any>(url, postData, this.httpOptions).pipe(
            tap((success) => (success)),
            catchError((err) => {
                if (handelError) {
                    this.handelError(err.error, currenRef)
                    return of()
                } else {
                    return of(err.error)
                }
            })
        );
    }

    /**
     * @Description Handel get request
     * 
     * @Params params[object], api[string], and isParams [string(query_params or params)]
     * @return object
     * 
     * @Author Avinash Jaiswal
     * */
    get(currenRef, params, api, isParams='', handelError = true): Observable<any> {
        var urlParams = ''
        if (isParams === Constants.QUERY_PARMAS) {
            for (let p in params) {
                urlParams = `${urlParams}${p}=${params[p]}&`
            }
            urlParams = urlParams.slice(0,-1)
        }

        if (isParams === Constants.PARAMS) {
            for (let p in params) {
                urlParams = `${urlParams}${params[p]}/`
            }
        }

        var url = `${Constants.API_URL}${api}/`
        if (urlParams != '') {
            url = (isParams === Constants.QUERY_PARMAS) ? `${url}?${urlParams}` : `${url}${urlParams}`
        }

        return this.httpClient.get<any>(url, this.httpOptions).pipe(
            tap((success) => (success)),
            catchError((err) => {
                if (handelError) {
                    this.handelError(err.error, currenRef)
                    return of()
                } else {
                    return of(err.error)
                }
            })
        );
    }

    /**
     * @Description Redirect to login if 401 otherwise show error message in toastr
     * 
     * @Params error
     * 
     * @Author Avinash Jaiswal
     * */
    private handelError(error, currenRef = '') {
        if (error.hasOwnProperty(Constants.STATUS_CODE)) {
            if (error[Constants.STATUS_CODE] == 401) {
                this.toastr.error(Constants.ERR_MSG.TOEN_EXPIRED_UNAUTHORIZED, Constants.ERROR)
                setTimeout(() => {
                    localStorage.setItem(Constants.TOKEN,'')
                    this.router.navigate([Constants.REDIRECT_URLS.LOGIN],{})
                }, this.milliSecond);
            } else {
                this.toastr.error(error[Constants.MSG], Constants.ERROR)
            }
        } else {
            this.toastr.error(Constants.ERR_MSG.SOMETHING_WRONG, Constants.ERROR)
        }

        if (currenRef) {
            this.responseMsg.hideSpinner(currenRef)
        }
    }

    /**
     * Description
     * @Make request for upload file
     *
     * @Author : Avinash Jaiswal
     *
     * */
    postWithFile(params, api) {
        var url = `${Constants.API_URL}${api}/`
        return this.httpClient.post(url, params);
    }

    /**
     * @Description update request
     *
     * @Author : Avinash Jaiswal
     *
     * */
    put(params, api, id) {
        var url = `${Constants.API_URL}${api}/`
        if (id != '') {
            url = `${Constants.API_URL}${api}/${id}/`
        }
        return this.httpClient.put(url, params);
    }


    /**
     * @Description delete request
     *
     * @Author : mahendar
     *
     * */
    delete(api, id) {
        var url = `${Constants.API_URL}${api}/`
        if (id != '') {
            url = `${Constants.API_URL}${api}/${id}/`
        }
        return this.httpClient.delete(url);
    }
    
    /**
     * @Description check is response is success
     *
     * @Author : mahendar
     *
     * */
    isSuccess(response) {
        return (response.hasOwnProperty(Constants.STATUS) && response[Constants.STATUS] == true)
    }

}
