import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { ImageModalComponent } from '../../components/image-modal/image-modal.component';

@NgModule({
    declarations: [
        SpinnerComponent,
        ImageModalComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SpinnerComponent,
        ImageModalComponent
    ]
})

export class AppCommonModule { }
