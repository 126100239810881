import { Component, OnInit } from '@angular/core';
import { ToggleMenuService } from 'src/app/shared/services/toggle-menu.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
    loggedInUsrData = JSON.parse(localStorage.getItem('currentUser'))
    subMenu;
    constructor( 
        public toggleMenu : ToggleMenuService,
    ) { }

    ngOnInit() {
    }

    toggleSubMenu(menuName){
        // this.subMenu[menuName] = (this.subMenu[menuName] === true) ? false : true ;
        this.subMenu = (this.subMenu == menuName) ? "NA" : menuName
        // if (this.subMenu == menuName){
        //     this.subMenu =  "close";
        // }
        // else {
        // this.subMenu =  menuName;
        //     // console.log("name nhi h")
        // }
    }

}
