import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    title = 'Ramblin';

    constructor(
        private titleService: Title,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    // ngOnInit() {
    //     this.titleService.setTitle(this.title);
    // }

    ngOnInit() {
        const appTitle = this.titleService.getTitle();
        console.log("appTitle : ", appTitle)
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
                map(() => {
                    // console.log("event : ", event)
                    // console.log("this activated route : ", this.activatedRoute)

                    let child = this.activatedRoute.firstChild

                    // console.log("child : ", child)
                    // console.log("child firstChild : ", child.firstChild)

                    while (child.firstChild) {
                        child = child.firstChild
                        console.log("child : ", child.snapshot.data['title'])
                    }
                    // console.log("child.snapshot.data['title'] : ", child.snapshot.data['title'])

                    if (child.snapshot.data['title']) {
                        // console.log("child.snapshot.data['title']")
                        return child.snapshot.data['title']
                    }
                    // console.log("appTitle : ", appTitle)
                    return appTitle;
                })
            ).subscribe((ttl: string) => {
                // console.log("ttl : ", ttl)
                this.titleService.setTitle(ttl);
            });
    }
}
