import { Component, OnInit } from '@angular/core';
import { Constants } from '../../../config/constants'
import { ToggleMenuService } from 'src/app/shared/services/toggle-menu.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit {
    constants: any

    constructor(
        public toggleMenu : ToggleMenuService
    ) { }

    ngOnInit() {
        this.constants = Constants
    }
}
