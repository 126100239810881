import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constants } from 'src/app/config/constants';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ResponseMsgService } from 'src/app/shared/services/response-msg.service';
import { CommonService } from 'src/app/shared/services/common.service';
// import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    constants: any
    passwordFildType: string = 'password'
    visibility: string = 'visibility'

    loginForm: FormGroup

    constructor(
        private authService: AuthService,
        private router: Router,
        private toastr: ToastrService,
        private fb: FormBuilder,
        private responseMsg: ResponseMsgService,
        private commonService: CommonService,
        // private titleService: Title
    ) {
        this.createLoginForm()
    }

    ngOnInit() {
        // this.titleService.setTitle('Ramblin | Login');
        this.responseMsg.setCurrentClsRef(this)
        this.constants = Constants
        const currentUser = this.authService.currentUserValue
        if (currentUser) {
            this.router.navigate([Constants.REDIRECT_URLS.DASHBOARD], {})
            return true
        }
    }

    /**
     * @Description used to access assigned loginForm.controls in short way
     * 
     * @return loginFormControls
     * 
     * @Author Avinash Jaiswal
     * */
    get logFCtrl() {
        return this.loginForm.controls
    }

    /**
     * @Description defines the form fields and validators for login form using an FormBuilder
     *  to create an instance of a FormGroup and stored in loginForm property
     * 
     * @Author Avinash Jaiswal
     * */
    createLoginForm() {
        this.loginForm = this.fb.group({
            username : ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)]],
            password : ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/)]]
        })
    }

    /**
     * @Description login in system
     * 
     * @Params form data
     * 
     * @Author Avinash Jaiswal
     * */
    onSubmit() {
    // onSubmit(formData: NgForm) {
        if (this.loginForm.invalid) {
            this.toastr.error(Constants.ERR_MSG.ENTER_VALID_CRED, Constants.ERROR)
            return;
        }

        this.responseMsg.showSpinner(this)
        if (this.loginForm.value.username && this.loginForm.value.password) {
            var postData = {
                op: 'login',
                email: this.loginForm.value.username,
                password: this.loginForm.value.password
            };

            this.authService.login(postData)
                .pipe(first())
                .subscribe(
                    success => {
                        this.responseMsg.hideSpinner(this)
                        if (success[Constants.STATUS] == true) {
                            this.toastr.success(Constants.SUCCESS_MSG.LOGIN_SUCCESS, Constants.SUCCESS)
                            let loggedInUsrData = JSON.parse(localStorage.getItem('currentUser'))

                            if (loggedInUsrData.user_type == 2) {//Resturant
                                this.commonService.getRestaurant()
                            }

                            if (loggedInUsrData.user_type == 1) {
                                this.commonService.restoDropDownData()
                            }

                            this.router.navigate([Constants.REDIRECT_URLS.DASHBOARD], {})
                        } else {
                            this.toastr.error(Constants.INFO_MSG.ADMIN_BUSINESS_USR_ALLOWED_ONLY, Constants.ERROR)
                            return
                        }
                    },
                    err => {
                        let error = err.error
                        let error_Message = Constants.ERR_MSG.SOMETHING_WRONG
                        if (error.hasOwnProperty('msg')) {
                            error_Message = error['msg']
                        }
                        this.toastr.error(error_Message, Constants.ERROR)
                        this.responseMsg.hideSpinner(this)
                    }, () => {
                        if (localStorage.getItem('currentUser'))
                            this.commonService.setUserType()
                    }
                )
        }
    }

    /**
     * @Description change password input field type as text/password
     *  and show/hide entered password
     * 
     * @Author Avinash Jaiswal
     * */
    showHidePassword() {
        if (this.passwordFildType == 'password') {
            this.passwordFildType = 'text';
            this.visibility = 'visibility'
        } else {
            this.passwordFildType = 'password';
            this.visibility = 'visibility_off'
        }
    }

    /**
     * @Description login in system
     * 
     * @Params form data
     * 
     * @Author Avinash Jaiswal
     * */
    // login(formData: NgForm) {
    //     if (formData.value.username && formData.value.password) {
    // 		var postData = {
    // 			op : 'login',
    // 			username : formData.value.username,
    //             password : formData.value.password
    //         };

    //         this.apiService.post(postData,Constants.APIS.USERACTIVITY, false).subscribe(
    //             response => {
    //                 if (response[Constants.STATUS] == true) {
    //                     localStorage.setItem('currentUser', JSON.stringify(response[Constants.DATA]))
    //                     this.router.navigate([Constants.REDIRECT_URLS.DASHBOARD],{})
    //                 } else {
    //                     alert(`Login Error :  ${response[Constants.DATA]}`)
    //                 }
    //             }
    //         )
    //     }
    // }
}
