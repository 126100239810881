import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToggleMenuService } from 'src/app/shared/services/toggle-menu.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
    loggedInUsrData = JSON.parse(localStorage.getItem('currentUser'))
    role: string = 'Admin'

    constructor(
        private authService: AuthService,
        public toggleMenu: ToggleMenuService,
    ) { }

    ngOnInit() {
        this.authService.profile_image = this.loggedInUsrData.profile_image;
        if (this.loggedInUsrData.user_type == 1) {
            this.role = 'Admin'
        } else {
            this.role = 'Business Owner Backend'
        }
    }
}
