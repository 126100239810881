import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
// import { Title } from '@angular/platform-browser';
// import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', '../../../../assets/scss/static-style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  nav = document.querySelector('#nav');
  toggleMenu = document.getElementById("toggle-nav");
  closeMenu = document.getElementById("close-nav");
  menuBar = document.querySelector('.main-menu');

  // title = 'Ramblin | Home Page';
  
  constructor(
    private router: Router,
    // private commonService: CommonService
  ) { }

  ngOnInit() {
    // this.commonService.setTitle(this.title)
  }

  goto(url){
    window.scrollTo(0, 0);
    this.router.navigate([url]);
  }
  
}
