let api = ''
let media = ''
let env_name = 'devlopment'
console.log("window.location.host : ", window.location.host);
if (window.location.host === "ramblin.cladev.com") {
	console.log("if 1");
	api = '//api.ramblin.cladev.com/api/v1.0/';
	media = '//api.ramblin.cladev.com/';
	env_name = 'stage';
} else if (["www.getramblin.com", "getramblin.com", "http://www.getramblin.com", "https://www.getramblin.com", "http://getramblin.com", "https://getramblin.com", "getramblin"].includes(window.location.host)) {
// else if (window.location.host === "www.getramblin.com") {
	console.log("if 2");
	api = "//api.getramblin.com/api/v1.0/";
	media = "//api.getramblin.com/";
	env_name = "production";
} else {
	console.log("if 3");
	// api = '//192.168.2.211:8000/api/v1.0/';
	// media = '//192.168.2.211:8000/';
	
	// staging
	api = '//api.ramblin.cladev.com/api/v1.0/';
	media = "//api.ramblin.cladev.com/";
	// api = '//localhost:8000/api/v1.0/';
	env_name = 'devlopment';

	// live
	// api = "//api.getramblin.com/api/v1.0/";
	// media = "//api.getramblin.com/";
	// env_name = "production";
}


export var env = {
	API_URL: api,
	MEDIA_URL: media,
	ENV_NAME: env_name
}