'use strict';
import { env } from './env';

export class Constants {
	public static API_URL =  env.API_URL
	public static MEDIA_URL = env.MEDIA_URL
	public static ENV_NAME = env.ENV_NAME
	public static TOKEN =  'token'
	public static STATUS =  'status'
	public static STATUS_CODE =  'status_code'
	public static ERROR =  'Error'
	public static SUCCESS =  'Success'
	public static DATA =  'data'
	public static MSG =  'msg'
	public static QUERY_PARMAS =  'query_params' //used for query string
	public static PARAMS =  'params' //used for url params,
	public static UNAUTHORIZED =  'Unauthorized'
	public static ACCESS_DENIED =  'Access Denied'
	public static ACTIVE =  'Active'
	public static IN_ACTIVE =  'In Active'
	public static RESULTS =  'results'
	public static FALSE =  'false'
	public static true =  'Yes'
	public static false =  'No'
	public static APIS =  {
		USERACTIVITY: 'useractivity',
		CHANGE_PASSWORD: 'users/changepassword',

		USERS: 'users',
		GET_USERS: 'users/get_user',
		UPDATE_CUSTOMER: 'profile/update_customer',
		USER_TYPE: 'usertype',
		USER_STATUS_CHANGE: 'users/active_inactive',

		// Dashboard
		DASHBOARD: 'dashboard/basic_db',
		ADMIN_CHART: 'dashboard/basic_db_graph',
		BUSINESS_CHART: 'dashboard/business_db_graph',

		// Profile
		GET_PROFILE: 'profile/get_profile',
		EDIT_PROFILE: 'profile/update_profile',

		// Requested resturant
		RESTURANT_REG_REQUEST: "registration_request",
		GET_REQUESTED_RESTURANT: "restaurant/get_restaurant",
		APPROV_REJECT_RESTO_REQ: "restaurant/restaurant_approval",
		RESTO_DROP_DOWN_DATA: 'restaurant/get_restra_dropdown',

		// Resturant
		RESTAURANTS: "restaurant",
		RESTAURANT_DETAILS: "restaurant/get_details",
		BUSINESS_TYP: 'item_type/businesstypedropdown',
		BUSINESS_STATUS_CHANGE: 'restaurant/activate_deactivate',

		// Menu
		MENU: "menu",
		MENU_FETCH: "menu/fetch_menu",
		MENU_FOR_DROPDOWN: "menu/list_menus",

		// Item Type
		ITEM_TYP: "item_type",
		ITEM_TYP_LIST: "item_type/list_itemtype",
		ITEM: "item",
		ITEM_FETCH: "item/fetch_item",
		ITEM_DELETE : 'orderitem',
		ITEM_UPDATE : 'orderitem/manageitemqty',

	    REVIEW_DEL : 'restaurantreview',

		
		SAVE_ITEM_SORTING : 'restaurant/set_menuorder',

		// Bartender
		BARTENDER : "bartender",
		ASSIGN_BARTENDER : "bartender/assignbartender",
		TEAM_MEMBER_DROPDOWN : "users/get_alltm",

		// Dropdown
		TABLES : 'diningtable',

		// Orders
		ORDERS : 'order',
		ORDERSTATUS : 'order/change_order_status',
		FETCH_ORDER : 'order_list/orderitemdetails',
		TEAM_MEMBER_ORDERS : 'order_list/orderserveddetails',

		// Reports
		ORDER_REPORTS : 'report/orderreports',
		REVENUE_REPORTS : 'report/revenuereports',
		CUSTOMER_REPORTS : 'report/customerreports',
		BAR_TENDER_REPORTS : 'report/bartenderreports',
		BUISNESS_REPORTS : 'report/restaurantreports',
		TEAM_MEMBER_REPORTS : 'report/tmemberreports',
		SERVED_BY : 'report/servedby',

		CONNECT_ACCOUNT : 'payment/connect_account',
		RESTO_CONNECT_ACCOUNT: 'resaccount/connected_account',

		// master
		MEASURES: 'measures'
	}
	public static REDIRECT_URLS =  {
		DASHBOARD: '/dashboard',
		LOGIN: '/login',
		RESTAURANT: 'restaurant',
		REQUESTED_RESTURANTS: "requested-restaurant/list",
		RESTURANT_SETUP: "restaurant/setup",
		ITEM_TYPE: "item/type",
		RESTAURANT_TYPE: "restaurant-type",
		MENU: "menu",
		ITEM: "item",
		USER: "user",
		BARTENDER: "bartender",
		ORDER: "order",
		CUSTOMER: "customer",

		MASTER: "master"
	}
	public static SUCCESS_MSG =  {
		LOGIN_SUCCESS: "Login Successful",
		REST_PASSWORD_LINK_SUCCESSFULLY_SEND: "A Verification Link has been sent to your Registered Email",
		RESTURANT_REQUEST_SEND_SUCC: "Success!  Your Request to join the Ramblin Community has been sent.  We will contact you soon to verify your info",
		RESTURANT_INFO_UPDATE_SUCC: "Business information updated successfully.",
		ITEM_ADD : 'Item added successfully.',
		ITEM_UPDATE : 'Item updated successfully',
		TEAMMEMBER_ADDED : 'Team Member added successfully',
		TEAMMEMBER_UPDATE : 'Team Member updated successfully',
		CUSTOMER_ADDED : 'Customer added successfully',
		CUSTOMER_UPDATE : 'Customer updated successfully',
		BUSINESS_TYP_ADD: 'Business type added successfully.',
		UNIT_MEAS_UPDAT_SUCSS: 'Unit measure updated successfully.'
	}
	public static ERR_MSG =  {
		SOMETHING_WRONG: 'Oops, there was a problem processing your request. Please try again or contact us at sales@getramblin.com',
		OLD_NEW_PASSWORD_SAME: 'Oops, Old and New Passwords cannot be the same',
		NOT_CHANGED_ERROR: 'You have not made any changes to save!!',
		ENTER_VALID_CRED: 'Please enter valid credentials.',
		TOEN_EXPIRED_UNAUTHORIZED: 'Session Expired or Unauthorized access, we are redirecting you to login screen.'
	}
	public static INFO_MSG =  {
		PASWRD_FORMT_ERR: 'Password must contain at least (6) characters, including (1) Number, (1) UPPERCASE, and (1) lowercase letter',
		VLID_USRNEM: 'Please enter a valid email address.',
		EMAIL_REQUIRED: 'Email ID is required.',
		CONFIRM_PASSWORD_IS_REQUIRED: "Password confirmation is required",
		PASSWORD_N_CONFIRMPASS_SHOULD_BE_SAME: "Password and Confirmation Password must be the same",
		
		VALID_OWNER_NAME: "Please enter a valid owner name.",
		VALID_RESTURANT_NAME: "Please enter a valid business name.",
		VALID_DBA_NAME: "Please enter a valid DBA name.",
		VALID_NAME: "Please enter a valid {fldName}.",
		SELECT_VALID_IMAGE: "Please select a valid image file.",

		VALID_PHONE_NO: "Please enter a phone number in format 000-000-0000.",
		VALID_STREET_ADDRESS:  "Please enter a valid street address.",
		VALID_UNIT_NO:  "Please enter a valid unit number.",
		VALID_CITY:   "Please enter a valid city.",
		VALID_STATE:   "Please enter a valid state.",
		VALID_COUNTRY:   "Please enter a valid country.",
		VALID_ZIP:  "Please enter a valid zip code.",
		VALID_LAT:  "Please enter a valid latitude.",
		VALID_LONG:  "Please enter a valid longitude.",
		ADDRESS_REQUIRED: "Please enter a valid address.",
		FEIN_REQUIRED: "Please enter a valid FEIN.",
		FILL_FRM_DATA: "Please fill out the form completely.",

		// restaurant setup form 
		STREET_REQUIRED: "Please enter a valid street address.",
		CITY_REQUIRED: "Please enter a valid city.",
		STATE_REQUIRED: "Please enter a valid state.",
		ZIP_REQUIRED: "Please enter a valid zip code.",
		OWNER_NAME: "Please enter a valid owner name.",
		RESTURANT_NAME_REQUIRED: "Please enter a valid business name.",
		BRAND_NAME_REQUIRED: "Please enter a valid brand name.",
		RESTURANT_EMAIL_REQUIRED: "Please enter a valid business email address..",
		RESTURANT_NUMB_REQUIRED: "Please enter a valid business number.",
		OPENING_TIME_REQUIRED: "Please enter a valid opening time, in hh:mm format.",
		CLOSING_TIME_REQUIRED: "Please enter a valid closing time, in hh:mm format.",
		SERVICE_CHOICE_REQUIRED: "Please select a valid service option.",
		NUMBER_OF_EMP_REQUIRED: "Please enter a valid number of employees.",
		NUMBER_OF_TBLS_REQUIRED: 'Please enter a valid number of Tables',
		SHORT_DESC_REQUIRED: "Please enter a valied Description.",
		// APPROX_TOTAL_PRICE_REQUIRED: "Please enter a valid approx total price.",
		// APPROX_TOTAL_PERSON_REQUIRED: "Please enter a valid approx total people.",
		// MONTHLY_TURNOVER_REQUIRED: "Please enter a valid monthly turnover.",
		PAY_METHOD_REQUIRED: "Please select a valid payment option",
		RESTAURANT_IMG_REQUIRED: "Please select a valid restaurant image.",
		RESTAURANT_BANNER_REQUIRED: "Please select a valid banner.",

		SELECT_OPTION: "Please select a valid {fldName} option.",

		// SELECT_FROM_OR_TO_DATE: 'Select at least one date; either from date or to date.',

		ITM_NO_ERR : 'Item Number Already Exists',
		BUSINESS_TYP_REQUIRED: 'Business type is required.',
		TAX_FORMAT: 'Please enter sales tax in 0.00% format',
		FEIN_FORMAT: 'Please enter FEIN in XX-XXXXXXX format only',
		ENTR_CONFIRM_PASS: 'Please enter password confirmation',
		ENTR_VALID_MENU: 'Please enter a valid menu name.',
		ENTR_AT_LEAST_ONE_CHAR: 'Please enter at least 1 character.',
		NOT_ALOWD_MORE_30: '30 Characters max for this field',
		ITM_NO_FORMT: 'Please enter Item Number in appropriate format; 1, 1.1, or 1.11',
		NOT_MORE_THEN_4_D: '4 Digits max for this field',
		CONNECT_ACC_EXIST: 'Account already exists',
		MOBILE_REQ: 'Mobile number is required.',
		CONNECT_FORM_FILLED_SUCCESS: 'Form Completed Successfully!  Click on "Complete Connection" to connect and finalize with Stripe',
		ADMIN_BUSINESS_USR_ALLOWED_ONLY: 'Access only allowed for Admin and Business Owner',
		ITM_QTY_UPDATED: 'Item quantity updated successfully.',
		QTY_SHOUL_GRTR_ZERO: 'Quantity should be greater than zero.',
	}
	public static API_MSG =  {
		API_ITM_NO_ERR : 'The fields code, restaurant must make a unique set.',
	}
	public static USER_TYPES =  {
		1: 'Admin',
		2: 'Restaurant',
		3: 'Bar Tendar',
		4: 'Customer'
	};
	public static ADD_USER_TYPES =  {
		3: 'Team Member',
		4: 'Customer'
	}
	public static USER_TYPES_NAME =  {
		ADMIN: 'Admin',
	}
	public static USER_TYPES_ID =  {
		ADMIN: 1,
	}
	public static DEFAULT_SELECT =  {
	}
	public static REQUESTED_RESTURANT_STATUS =  {
		0: 'PENDING',
		1: 'APPROVED',
		2: 'REJECTED'
	}
	public static SERVING_CHOICE =  {
		"bar": "Bar",
		"pickup": "Pickup",
		"table_service": "Table"
	}
	public static PAYMENT_CHOICE =  {
		"cash": "Cash",
		"online": "Via App",
		"credit_debit": "Credit/Debit"
	}
	public static ACTIVE_CHOICE =  {
		"true": "Yes",
		"false": "No",
	}
	public static ITEM_SIZE_UNIT =  {
		"l": "L",
		"ml": "ML",
		"lb": "LB",
		"kg": "KG",
		"g": "G",
		"c": "C",
		"plate": "PLATE",
		"bottle": "BOTTLE",
		"glass": "GlASS",
	}
	public static STATUS_CHOICE =  {
		0: 'InActive',
		1: "Active",
	}
	public static CLIENT_ID_STRIP =  {
		devlopment: 'ca_GKH3n3UOAhKLGCFmlicjsAm1LNtgSRIw',
		stage: 'ca_GKH3n3UOAhKLGCFmlicjsAm1LNtgSRIw',
		production: 'ca_GKH3ufxawqQtgY6MJojp8nswrWproVYS',
	}
	public static ORDER_STATUS =  {
		0: 'OPEN',
		1: 'COMPLETED',
		2: 'PLACED',
		3: 'ACCEPTED',
		4: 'REJECTED',
	}
	public static ORDER_FROM =  {
		0: 'pickup',
		1: 'table service',
		2: 'bar service',
	};
	public static ORDER_TYPE_DISPLAY =  {
		0: 'Pickup',
		1: 'Table',
		2: 'Bar',
	};
	public static PLACEHOLDERS = {
		SEARCH_FILTER : 'Search & filter'
	};
	public static GOOGLE_MAP_KEY =  'AIzaSyDhqlji7ukGTuVRE1wcY-965F8r6vYPYjw';
	public static TOSTAR =  {
		closeButton: true,
		timeOut: 7000,
	};
}