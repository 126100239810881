import { Component, OnInit, ViewEncapsulation, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Constants } from 'src/app/config/constants';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { ResponseMsgService } from 'src/app/shared/services/response-msg.service';
import { ToastrService } from 'ngx-toastr';
import { MapsAPILoader } from '@agm/core';

@Component({
    selector: 'app-enroll-now',
    templateUrl: './enroll-now.component.html',
    styleUrls: ['./enroll-now.component.scss', '../../../assets/scss/static-style.scss'],
    encapsulation: ViewEncapsulation.None
})

export class EnrollNowComponent implements OnInit {
    @ViewChild('search') searchElementRef: ElementRef
    address: any = {}

    constants: any
    resturantRegFrm: FormGroup

    hideRegForm: boolean = false
    mask: any

    constructor(
        private fb: FormBuilder,
        private toastr: ToastrService,
        private responseMsg: ResponseMsgService,
        private apiService: ApiService,
        private commonService: CommonService,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        this.commonService.feinMask = ''
        this.commonService.mask = ''
        this.constants = Constants
        this.responseMsg.setCurrentClsRef(this)
        this.createRegistrtionFrm()

        this.mapsAPILoader.load().then(() => {
            let autocomplete = new google.maps.places.Autocomplete(
                this.searchElementRef.nativeElement
            )

            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    let place : google.maps.places.PlaceResult = autocomplete.getPlace()
                    
                    if (place.geometry === undefined || place.geometry === null)
                        return
                    
                    this.address['address'] = place.formatted_address;
                    this.address['latitude'] = place.geometry.location.lat()
                    this.address['longitude'] = place.geometry.location.lng()

                    let street_address, unit_number, city, state, zip, country;

                    place.address_components.forEach(function(component) {
                        let types = component.types;

                        if (types.indexOf('route') > -1) {
                            street_address = component.long_name;
                        }

                        if (types.indexOf('locality') > -1) {
                            city = component.long_name;
                        }

                        if (types.indexOf('administrative_area_level_1') > -1) {
                            state = component.long_name;
                        }

                        if (types.indexOf('postal_code') > -1) {
                            zip = component.long_name;
                        }

                        if (types.indexOf('country') > -1) {
                            country = component.long_name;
                        }

                        if (types.indexOf('street_number') > -1) {
                            unit_number = component.long_name;
                        }
                    });

                    this.address['street_address'] = unit_number
                    this.address['unit_number'] = unit_number
                    this.address['city'] = city
                    this.address['state'] = state
                    this.address['country'] = country
                    this.address['zip_code'] = zip

                    console.log("this.address : ", this.address)

                    this.resturantRegFrm.patchValue({
                        street_address: street_address,
                        unit_number: unit_number,
                        city : city,
                        state : state,
                        country: country,
                        zip_code: zip,
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng()
                    })
                })
            })
        })
    }

    /**
     * @Description defines the form fields and validators for resturant registration form using an FormBuilder
     *  to create an instance of a FormGroup and stored in resturantRegFrm property
     * 
     * @Author Avinash Jaiswal
     * */
    createRegistrtionFrm(){
        this.resturantRegFrm = this.fb.group({
            owner_name: ['', Validators.required],
            restaurant_name: ['', Validators.required],
            dba: [''],
            // phone_number: ['', Validators.required, Validators.pattern(/^\d{3}-\s?\d{3}-\d{4}$/)],
            phone_number: ['', Validators.required],
            // phone_no : this.fb.group({
            //     part1: [''],
            //     part2: [''],
            //     part3: [''],
            // }),
            restaurant_email: ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)]],
            fein: ['', [Validators.required, Validators.pattern(/^\d{2}-\s?\d{7}$/)]],
            // fein: ['', [Validators.pattern(/^[\w]{2}[\-][\w]{7}$/)]],
            restaurnt_address: [''],
            street_address: ['', Validators.required],
            unit_number: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            country: ['', Validators.required],
            zip_code: ['', Validators.required],
            latitude: [''],
            longitude: [''],
        })
    }

    /**
     * @Description used to access assigned resturantRegFrm.controls in short way
     * 
     * @return resturantRegFrm control
     * 
     * @Author Avinash Jaiswal
     * */
    get rsturntRegFrmCtrl() {
        return this.resturantRegFrm.controls
    }

    /**
     * @Description request for resturant registraion
     * 
     * @Author Avinash Jaiswal
     * */
    phoneNo:any
    onSubmit() {
        if (this.resturantRegFrm.invalid) {
            this.toastr.error(Constants.INFO_MSG.FILL_FRM_DATA, Constants.ERROR)
            return;
        }

        // this.responseMsg.showSpinner(this)

        var formData = this.resturantRegFrm.value;
        // this.phoneNo = formData.phone_no
        // // delete formData.phone_no
        // if ((this.phoneNo.part1.length != 3) &&  (this.phoneNo.part2.length != 3) && (this.phoneNo.part3.length != 4))
        //     this.toastr.error(Constants.INFO_MSG.VALID_PHONE_NO, Constants.ERROR)


        // formData['phone_number'] = `${this.phoneNo.part1}-${this.phoneNo.part2}-${this.phoneNo.part3}`
        console.log(formData)
        // if (!formData.restaurnt_address)
            // formData.restaurnt_address = formData.street_address
        // return true

        this.apiService.post(this, formData, Constants.APIS.RESTURANT_REG_REQUEST, false).subscribe(
            response => {
                if (response.hasOwnProperty(Constants.STATUS) && response[Constants.STATUS] == true) {
                    this.hideRegForm = true
                    // this.toastr.success(response[Constants.SUCCESS_MSG.RESTURANT_REQUEST_SEND_SUCC], Constants.SUCCESS)
                } else {
                    var errorMessage = Constants.ERR_MSG.SOMETHING_WRONG
                    if (response.hasOwnProperty(Constants.MSG)) {
                        errorMessage = response[Constants.MSG]
                    }
                    this.toastr.error(errorMessage, Constants.ERROR)
                }
                this.responseMsg.hideSpinner(this)
            }
        )
    }
}
