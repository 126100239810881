import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class ResponseMsgService {
    spinner: any = []
    // currentClsRef: any

    constructor() { }

    /**
     * @Description show sppiner for current reference
     * 
     * @Author Avinash Jaiswal
     * */
    showSpinner(currentClsRef:any) {
        // this.currentClsRef = currentClsRef
        this.spinner[currentClsRef] = true
    }

    /**
     * @Description hide sppiner for current reference
     * 
     * @Author Avinash Jaiswal
     * */
    hideSpinner(currentClsRef:any) {
        // this.currentClsRef = currentClsRef
        this.spinner[currentClsRef] = false
    }

    /**
     * @Description set default value for current reference's sppiner
     * 
     * @Author Avinash Jaiswal
     * */
    setCurrentClsRef(currentClsRef:any, flag:boolean = false) {
        // this.currentClsRef = currentClsRef
        this.spinner[currentClsRef] = flag
    }
}