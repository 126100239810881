import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/config/constants';
import { ApiService } from 'src/app/shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ResponseMsgService } from 'src/app/shared/services/response-msg.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
    newPass: string = "password"
    newPassVisibility: string = "visibility_off"
    confPass: string = "password"
    confPassVisibility: string = "visibility_off"

    constants: any

    showForLogin: boolean = false
    resetPasswordForm: FormGroup

    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private responseMsg: ResponseMsgService,
        private fb: FormBuilder,
    ) { }

    ngOnInit() {
        this.constants = Constants
        this.createResetPassForm()
        this.responseMsg.setCurrentClsRef(this)
    }

    /**
     * @Description used to access assigned resetPasswordForm.controls in short way
     * 
     * @return loginFormControls
     * 
     * @Author Avinash Jaiswal
     * */
    get resetFrmCtrl() {
        return this.resetPasswordForm.controls
    }

    /**
     * @Description defines the form fields and validators for login form using an FormBuilder
     *  to create an instance of a FormGroup and stored in loginForm property
     * 
     * @Author Avinash Jaiswal
     * */
    createResetPassForm() {
        this.resetPasswordForm = this.fb.group({
            new_password : ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/)]],
            confirm_password : ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/)]]
        })
    }

    /**
     * @Description reset password via password link
     * 
     * @Author Avinash Jaiswal
     * */
    onSubmit() {
    // onSubmit(formData: NgForm) {
        if (this.resetPasswordForm.invalid) {
            this.toastr.error(Constants.INFO_MSG.FILL_FRM_DATA, Constants.ERROR)
            return;
        }

        this.responseMsg.showSpinner(this)

        var formData = this.resetPasswordForm.value
        if (formData.new_password == formData.confirm_password) {
            const resetPasswordKey = this.route.snapshot.paramMap.get('id');
            var params = {
                "op": "reset_password",
                "reset_password_key": resetPasswordKey,
                "new_password": formData.new_password,
                "confirm_password": formData.confirm_password,
            }

            this.apiService.post(this, params, Constants.APIS.USERACTIVITY, false).subscribe(
                response => {
                    if (response.hasOwnProperty(Constants.STATUS) && response[Constants.STATUS] == true) {
                        this.toastr.success(response[Constants.MSG], Constants.SUCCESS)
                        this.showForLogin = true
                    } else {
                        var errorMessage = Constants.ERR_MSG.SOMETHING_WRONG
                        if (response.hasOwnProperty(Constants.MSG)) {
                            errorMessage = response[Constants.MSG]
                        }
                        this.toastr.error(errorMessage, Constants.ERROR)
                    }
                    this.responseMsg.hideSpinner(this)
                }
            );
        } else {
            this.responseMsg.hideSpinner(this)
            this.toastr.error(Constants.INFO_MSG.PASSWORD_N_CONFIRMPASS_SHOULD_BE_SAME, Constants.ERROR)
        }
    }

    /**
     * @Description change password input field type as text/password
     *  and show/hide entered password
     * 
     * @Author Avinash Jaiswal
     * */
    showHidePassword(curVal) {
        if (curVal == 'newPass') {
            if (this.newPass == 'password') {
                this.newPass = 'text';
                this.newPassVisibility = 'visibility'
            } else {
                this.newPass = 'password';
                this.newPassVisibility = 'visibility_off'
            }
        } else if (curVal == 'confPass') {
            if (this.confPass == 'password') {
                this.confPass = 'text';
                this.confPassVisibility = 'visibility'
            } else {
                this.confPass = 'password';
                this.confPassVisibility = 'visibility_off'
            }
        }
    }
}