import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss', '../../../../assets/scss/static-style.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class FaqComponent implements OnInit {

  faq = [];
  faqsListEnglish = [
  {
    title: `What Merchants can join the Ramblin Community?`,
    description: `Any merchant can join the community and use the Ramblin platform.  It’s free and easy to get up and running fast!`

  },
  {
    title: `What operating systems are supported?`,
    description: `Ramblin supports iOS and Android platforms.  Web Interface is accessible with Chrome, Firefox, Mozilla, or Explorer.`

  },
  {
    title: `What type of hardware is required?`,
    description: `No special hardware is required to get up and running, just use your iOS or Android smartphone.  Merchants can build their menu and customize their business information through the web interface, using Chrome, Firefox, Mozilla, or Explorer.`

  },
  {
    title: `How do I search for nearby merchants?`,
    description: `Turn on “Location” on your device.  Ramblin automatically searches for Nearby Merchants in the “Near Me” screen.  All Merchants that are operating in the Ramblin Community will be shown in the list.`

  },
  {
    title: `What if I don’t see the Merchant that I want to use?`,
    description: `Ramblin only shows Merchants that are currently using the Ramblin platform.  You can ask your favorite Merchants to join the Ramblin community.  It’s Free to join!`

  },
  {
    title: `How do I search for a specific item type?`,
    description: `Use the “Explore” screen to search by item type.  Each Merchant that offers that type of item will be shown in the list.`

  },
  {
    title: `How do I place an order and submit my selected items?`,
    description: `Once you find a Merchant, select the service location that applies to you.  You can select Table Number, Bar, or Self Pickup.  Then, review the Merchant’s menu of items and select the item(s) that you want.  Once you are done selecting items, go to the “Tab” screen where you can review and confirm the selected items are correct, then click the “Submit These Items Now” button.  Your order will be routed to the appropriate Team Member for review and acceptance.`

  },
  {
    title: `How do I know if my order was received?`,
    description: `Once your order is submitted to the Merchant, it is routed to the appropriate Team Member who will review your order.  If everything looks good, they will “accept” your order and you will receive a notification that the order was accepted.`

  },
  {
    title: `Why did I receive an “Order Rejected” notification?`,
    description: `If there is an issue with your order, a Team Member can reject the order.  Rejection notifications will show the reason that the order was rejected.  You can then select new items and resubmit a new order.`

  },
  {
    title: `Can I submit more than one order at a time?`,
    description: `You can only submit one (batch) order for processing at a time.  Once your current order has been delivered to your service location, you can then place another order under the same open “tab”.`

  },
  {
    title: `How can a Team Member verify my order when I am at the Self Pickup Location?`,
    description: `The “Tab” screen also shows a flash screen at the top of the page.  The flash screen includes your picture and order ID number.  The Team Member can match your order ID number when delivering the order to you.`

  },
  {
    title: `Can I keep my tab open?`,
    description: `Yes!  You may keep your tab open until you are ready to close-out.  So, you only process one payment.`,
  },
  {
    title: `How do I close-out my tab?`,
    description: `Once all of your orders have been delivered to your selected Service Location, you may close-out your tab.  Just click on the “Tab” screen and select “Close Out Tab”.  Enter a tip or gratuity amount and select “Go to Check Out”.  This will take you to the “Payment” Screen where you can enter your Credit Card information.`,
  },
  {
    title: `Do I have to use a Credit Card?`,
    description: `Each merchant can select the type of payment that they accept, Cash, Credit, or via App.  If you want to pay by cash or credit, not using the Ramblin App, just let your Team Member know when you are ready to close-out your tab.`,
  },
  {
    title: `How do I add gratuity to my tab?`,
    description: `A “Tip” or gratuity can be entered in the “Total Tab” screen.  You can access this screen from the “Tab” screen and select the “Close Out Tab” button at the bottom of the screen.  Then, enter the tip amount in the “Add Tip / Gratuity” window.`,
  },
  {
    title: `What forms of payment are accepted?`,
    description: `Each Merchant can select the type of payments that are accepted.  They may include any or all options, including credit card, cash, or via App.  Check with your Merchant if you have any questions regarding payment option.`,
  },
  {
    title: `Are Customers charged anything for using the App?`,
    description: `No!  Customers do not pay any fees for using the Ramblin App.  `,
  },
  {
    title: `How do I change the language?`,
    description: `If you want to change the language from English to Spanish, go to the “Help” page, then select “Change Language”.`,
  },
  {
    title: `How can I rate my experience?`,
    description: `We encourage you to always leave feedback to share with other Ramblers.  To rate your experience, go to the “Account” screen and select “Your Orders”.  Select the order from the list, then select “Liked? Write a Review”.`,
  },
  {
    title: `How do merchants setup payment gateway accounts?`,
    description: `Merchants can receive credit card payments via App by connecting to Stripe.  A Stripe Merchant Account can easily be setup directly from the Ramblin Merchant Web Backend.  Once approved, the Merchant can receive credit card payments directly through the Ramblin App.`,
  },
  {
    title: `Do any Credit Card payments go directly to Ramblin?`,
    description: `No, all merchant accounts are handled directly between the Merchant and Stripe.  Stripe is the processing gateway that Ramblin currently uses. `,
  }
]
  faqsListSpanish = [
    {
      title: `¿Qué comerciantes pueden unirse a la Comunidad Ramblin ?`,
      description: `Cualquier comerciante puede unirse a la comunidad y usar la plataforma Ramblin.¡Es gratis y fácil de poner en marcha rápidamente!`
    },
    {

      title: `    ¿Cuánto cuesta usar la plataforma Ramblin ?`,
      description: `La plataforma Ramblin es gratuita para clientes y comerciantes.`
    },
    {

      title: `¿Qué sistemas operativos son compatibles ?`,
      description: `Ramblin es compatible con plataformas iOS y Android.Se puede acceder a la Interfaz Web con Chrome, Firefox, Mozilla o Explorer.`
    },
    {

      title: `¿Qué tipo de hardware se requiere ?`,
      description: `No se requiere hardware especial para comenzar a funcionar, solo use su teléfono inteligente iOS o Android.Los comerciantes pueden crear su menú y personalizar su información comercial a través de la interfaz web, utilizando Chrome, Firefox, Mozilla o Explorer.`
    },
    {

      title: `¿Cómo busco comerciantes cercanos ?`,
      description: `Active "Ubicación" en su dispositivo.Ramblin busca automáticamente Comerciantes cercanos en la pantalla "Cerca de mí".Todos los comerciantes que operan en la Comunidad Ramblin se mostrarán en la lista.`
    },
    {

      title: `¿Qué sucede si no veo el comerciante que quiero usar ?`,
      description: `Ramblin solo muestra a los comerciantes que actualmente utilizan la plataforma Ramblin.Puede pedirle a sus comerciantes favoritos que se unan a la comunidad de Ramblin.¡Es gratis unirse!`
    },
    {

      title: `¿Cómo busco un tipo de artículo específico ?`,
      description: `Use la pantalla "Explorar" para buscar por tipo de elemento.Cada comerciante que ofrece ese tipo de artículo se mostrará en la lista.`
    },
    {

      title: `¿Cómo hago un pedido y envío mis artículos seleccionados ?`,
      description: `Una vez que encuentre un comerciante, seleccione la ubicación del servicio que le corresponda.Puede seleccionar Número de tabla, Barra o Recogida automática.Luego, revise el menú de artículos del Comerciante y seleccione los artículos que desee.Una vez que haya terminado de seleccionar elementos, vaya a la pantalla "Pestaña" donde puede revisar y confirmar que los elementos seleccionados son correctos, luego haga clic en el botón "Enviar estos elementos ahora".Su pedido se enviará al miembro del equipo correspondiente para su revisión y aceptación.`
    },
    {

      title: `¿Cómo sé si se recibió mi pedido ?`,
      description: `Una vez que su pedido se envía al Comerciante, se enruta al Miembro del equipo correspondiente que revisará su pedido.Si todo se ve bien, "aceptarán" su pedido y recibirá una notificación de que el pedido fue aceptado.`
    },
    {

      title: `¿Por qué recibí una notificación de "Pedido rechazado" ?`,
      description: `Si hay un problema con su pedido, un miembro del equipo puede rechazar el pedido.Las notificaciones de rechazo mostrarán el motivo por el que se rechazó el pedido.Luego puede seleccionar nuevos artículos y volver a enviar un nuevo pedido.`
    },
    {

      title: `¿Puedo enviar más de un pedido a la vez ?`,
      description: `Solo puede enviar un pedido(por lotes) para su procesamiento a la vez.Una vez que su pedido actual ha sido entregado a su ubicación de servicio, puede realizar otro pedido en la misma "pestaña" abierta.`
    },
    {

      title: `¿Cómo puede un miembro del equipo verificar mi pedido cuando estoy en la ubicación de recogida automática ?`,
      description: `La pantalla "Pestaña" también muestra una pantalla flash en la parte superior de la página.La pantalla flash incluye su imagen y número de identificación de pedido.El miembro del equipo puede coincidir con el número de identificación de su pedido cuando le entregue el pedido.`
    },
    {

      title: `¿Puedo mantener mi pestaña abierta ?`,
      description: `¡Si! Puede mantener su pestaña abierta hasta que esté listo para cerrar.Entonces, solo procesas un pago.`
    },
    {

      title: `¿Cómo cierro mi pestaña ?`,
      description: `Una vez que todos sus pedidos se hayan entregado en la Ubicación de servicio seleccionada, puede cerrar su pestaña.Simplemente haga clic en la pantalla "Pestaña" y seleccione "Cerrar pestaña".Ingrese un monto de propina o propina y seleccione "Ir a la salida".Esto lo llevará a la pantalla de "Pago" donde puede ingresar la información de su tarjeta de crédito.`
    },
    {

      title: `¿Tengo que usar una tarjeta de crédito ?`,
      description: `Cada comerciante puede seleccionar el tipo de pago que acepta, efectivo, crédito o mediante la aplicación.Si desea pagar en efectivo o con crédito, sin usar la aplicación Ramblin, simplemente informe a su miembro del equipo cuando esté listo para cerrar su pestaña.`
    },
    {

      title: `¿Cómo agrego propina a mi pestaña ?`,
      description: `Se puede ingresar una "Propina" o propina en la pantalla "Pestaña Total".Puede acceder a esta pantalla desde la pantalla "Pestaña" y seleccionar el botón "Cerrar pestaña" en la parte inferior de la pantalla.Luego, ingrese el monto de la propina en la ventana "Agregar propina / propina".`
    },
    {

      title: `¿Qué formas de pago se aceptan ?`,
      description: `Cada comerciante puede seleccionar el tipo de pagos que se aceptan.Pueden incluir cualquiera o todas las opciones, incluyendo tarjeta de crédito, efectivo o mediante la aplicación.Consulte con su comerciante si tiene alguna pregunta sobre la opción de pago.`
    },
    {

      title: `¿Se cobra a los clientes algo por usar la aplicación ?`,
      description: `¡No! Los clientes no pagan ninguna tarifa por usar la aplicación Ramblin.`
    },
    {

      title: `¿Cómo cambio el idioma ?`,
      description: `Si desea cambiar el idioma de inglés a español, vaya a la página de "Ayuda", luego seleccione "Cambiar idioma".`
    },
    {

      title: `¿Cómo puedo calificar mi experiencia ?`,
      description: `Le recomendamos que siempre deje comentarios para compartir con otros Ramblers.Para calificar su experiencia, vaya a la pantalla "Cuenta" y seleccione "Sus pedidos".Seleccione el orden de la lista, luego seleccione "Me gusta? Escribe una reseña".`
    },
    {

      title: `¿Cómo configuran los comerciantes las cuentas de pasarela de pago ?`,
      description: `Los comerciantes pueden recibir pagos con tarjeta de crédito a través de la aplicación conectándose a Stripe.Una cuenta de comerciante de Stripe se puede configurar fácilmente directamente desde el backend web del comerciante de Ramblin.Una vez aprobado, el Comerciante puede recibir pagos con tarjeta de crédito directamente a través de la aplicación Ramblin.`
    },
    {

      title: `¿Los pagos con tarjeta de crédito van directamente a Ramblin ?`,
      description: `No, todas las cuentas comerciales se manejan directamente entre el comerciante y Stripe.Stripe es la pasarela de procesamiento que Ramblin usa actualmente.`
    }
  ]








  lang = 'en';







  isAppView = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // console.log(this.route.snapshot.queryParams['appmode'] == 'true');
    this.isAppView = (this.route.snapshot.queryParams['appmode'] == 'true');
    this.lang = this.route.snapshot.queryParams['lang'] || 'en'
  }
  toggleList(list){
    if (this.faq[list] == true){
      this.faq[list] = false;
    }
    else{
      this.faq[list] = true;
    }
  }
}
