import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss', '../../../../assets/scss/static-style.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ContactUsComponent implements OnInit {
    nav:any = document.querySelector('#nav');
    toggleMenu:any = document.getElementById("toggle-nav");
    closeMenu:any = document.getElementById("close-nav");
    menuBar:any = document.querySelector('.main-menu');
    

    constructor() { }

    ngOnInit() {
    }

    
}