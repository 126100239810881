import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-how-works',
  templateUrl: './how-works.component.html',
  styleUrls: ['./how-works.component.scss', '../../../../assets/scss/static-style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HowWorksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
