import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageModalService {

  openModal: boolean = false
  imgSource: any 
  constructor() { }

  showModal(imgSource){
    
    //console.log(imgSource);
    this.imgSource = imgSource;
    this.openModal = true;
    // if(imgSource != '') {
    //   console.log('come inside image src')
    //   this.imgSource = imgSource;
    //   this.openModal = true;
    // }
    // else {
    //   console.log('comes inside error')
    //   this.imgSource = '';
    //   this.openModal = false;
    // }
    
  }
  hideModal(){
    this.openModal = false;
  }

}
