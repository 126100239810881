import { Injectable } from '@angular/core';
import { Constants } from 'src/app/config/constants';
import { ApiService } from './api.service';
import { ResponseMsgService } from './response-msg.service';
// import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})

export class CommonService {
    restaurant: any
    itemTypes: any
    menus: any
    mask: any
    dropdownResto:any
    // title = 'Ramblin';

    constructor (
        private api: ApiService,
        private responseMsg: ResponseMsgService,
        // private titleService: Title
    ) { }

    // setTitle(title) {
        // this.titleService.setTitle(title || this.title);
    // }

    /**
     * @Description get restaurant
     * 
     * @Author Avinash Jaiswal
     * */
    getRestaurant(restaurantId: number = 0){
        var params = {}
        this.api.get('loadRestaurantData', params , Constants.APIS.RESTAURANT_DETAILS).subscribe(
            response => {
                if (response.hasOwnProperty(Constants.STATUS) && response[Constants.STATUS] == true) {
                    this.restaurant = response[Constants.DATA]

                    if (this.restaurant.hasOwnProperty('sort_on') && this.restaurant.hasOwnProperty('sort_by') && (this.restaurant.sort_by != 'reset')) {
                        let itemPagination = { sort_on : this.restaurant.sort_on, sort_by : this.restaurant.sort_by }
                        localStorage.removeItem('itempagination')
                        localStorage.setItem('itempagination', JSON.stringify(itemPagination))
                    } else {
                        localStorage.removeItem('itempagination')
                    }

                    localStorage.removeItem('restaurant')
                    localStorage.setItem('restaurant', JSON.stringify(this.restaurant))
                }
            }
        )
    }

    /**
     * @Description list item types
     * 
     * @Author Avinash Jaiswal
     * */
    getItemTyps(currentRef, params , paramsType) {

        this.responseMsg.showSpinner(currentRef)
        return this.api.get(currentRef, params, Constants.APIS.ITEM_TYP_LIST, paramsType, false)

        // .subscribe(
        //     response => {
        //         if (response.hasOwnProperty(Constants.STATUS) && response[Constants.STATUS] == true) {
        //             this.itemTypes = response[Constants.DATA]
        //             // console.log("items from common services")
        //             // console.log(this.itemTypes)

        //             this.responseMsg.hideSpinner(currentRef)
        //         } else {
        //             localStorage.removeItem('menus')
        //             var error_message = Constants.ERR_MSG.SOMETHING_WRONG
        //             if (response.hasOwnProperty(Constants.MSG)) {
        //                 error_message = response[Constants.MSG]
        //             }
        //             // this.toastr.error(error_message, Constants.ERROR)
        //             this.responseMsg.hideSpinner(currentRef);
        //         }
        //     }
        // )
    }

    /**
     * @Description list menus
     * 
     * @Author Avinash Jaiswal
     * */
    getMenus(currentRef, params, paramsType) {
        // var params = {
        //     restaurant: '',
        //     sort_on: '',
        //     sort_by: ''
        // }
        // var paramsType = Constants.QUERY_PARMAS
        // if (sort_on && sort_by) {
        //     params['sort_on'] = sort_on
        //     params['sort_by'] = sort_by
        // }
        this.responseMsg.showSpinner(currentRef)
        // var restaurant = JSON.parse(localStorage.getItem('restaurant'))
        // params['restaurant'] = restaurant.id
        // console.log(params)
        return this.api.get(currentRef, params, Constants.APIS.MENU, paramsType, false)
        // .subscribe(
        //     response => {
        //         if (response.hasOwnProperty(Constants.STATUS) && response[Constants.STATUS] == true) {
        //             this.menus = response[Constants.DATA]
        //             // console.log("common service se menu data")
        //             // console.log(this.menus)
        //             // localStorage.removeItem('menus')
        //             // localStorage.setItem('menus', JSON.stringify(this.menus))
        //             this.responseMsg.hideSpinner(currentRef)
        //         } else {
        //             // localStorage.removeItem('menus')
        //             var error_message = Constants.ERR_MSG.SOMETHING_WRONG
        //             if (response.hasOwnProperty(Constants.MSG)) {
        //                 error_message = response[Constants.MSG]
        //             }
        //             // this.toastr.error(error_message, Constants.ERROR)
        //             this.responseMsg.hideSpinner(currentRef);
        //         }
        //     }
        // )
    }

    /**
     * @Description allowed only traverse right and left, in input field
     * 
     * @Author Avinash Jaiswal
     * */
    allowedOnlyTraverse(e) {
        console.log(e.which)
        if (e.which !== 35 && e.which !== 36 && e.which !== 37 && e.which !== 39) {
            e.preventDefault()
        }
    }

    /**
     * @Description set user type in localstorage
     * 
     * @Author Avinash Jaiswal
     * */
    setUserType(currentRef='') {
        // this.responseMsg.showSpinner(currentRef)
        this.api.get(currentRef, {}, Constants.APIS.USER_TYPE).subscribe(
            response => {
                // console.log("response ----")
                // console.log(response)
                if (response.hasOwnProperty(Constants.STATUS) && response[Constants.STATUS] == true) {
                    localStorage.setItem('userTypes', JSON.stringify(response[Constants.DATA]))
                    // this.responseMsg.hideSpinner(currentRef)
                }
            }
        )
    }


    // mask set 
    setMask(event){   
        // console.log(event)     
        var keys = event.charCode || event.keyCode || 0;

        if(keys !== 8 && keys !== 9){
            var keyuse
            if(keys >= 37 && keys <= 40){
                keyuse = ''
            }
            else {
                keyuse = event.key
            }

            let val = event.target.value
            let valLen = val.length
            if(valLen == 3 && keyuse !== '') {
                this.mask = val + '-' + keyuse
                return false
            }
            if(valLen == 7 && keyuse !== '') {
                this.mask = val + '-' + keyuse
                return false
            }
            if(valLen > 12 ) {
                this.mask = event.target.value
                return false
            }
        }

        return (keys == 8 || 
            keys == 9 ||
            (keys >= 48 && keys <= 57) ||            
            (keys >= 96 && keys <= 105));	
    }

    /**
     * @Description: get and set restaurant dropdown data in localstorage
     * 
     * @Author: Avinash Jaiswal
     * */
    restoDropDownData(currentRef='') {
        // console.log("from restoDropDownData")
        this.api.get(currentRef, {}, Constants.APIS.RESTO_DROP_DOWN_DATA).subscribe(
            response => {
                // console.log("restoDropDownData response ----")
                // console.log(response)
                if (response.hasOwnProperty(Constants.STATUS) && response[Constants.STATUS] == true) {
                    this.dropdownResto = response[Constants.DATA]
                    // console.log("restoDropDownData : ", this.dropdownResto)
                    // let a = {}
                    // this.dropdownResto.forEach(element => {
                        // a[element.id] = element
                    // });
                    // console.log("created a : ", a)
                    localStorage.setItem('restoDropdown', JSON.stringify(response[Constants.DATA]))
                    // this.responseMsg.hideSpinner(currentRef)
                }
            }
        )
    }

    /**
     * @Description: get and set restaurant dropdown data in localstorage
     * 
     * @Author: Avinash Jaiswal
     * */
    teamMembrDropDownData: any
    teamMembrDropDown(currentRef='') {
        console.log("from teamMembrDropDown")
        this.responseMsg.showSpinner(currentRef)
        this.api.get(currentRef, {}, Constants.APIS.TEAM_MEMBER_DROPDOWN).subscribe(
            response => {
                console.log("teamMembrDropDown response ---- : ", response)
                if (response.hasOwnProperty(Constants.STATUS) && response[Constants.STATUS] == true) {
                    this.teamMembrDropDownData = response[Constants.DATA]
                    console.log("teamMembrDropDownData : ", this.teamMembrDropDownData)
                    this.responseMsg.hideSpinner(currentRef)
                } else {
                    this.responseMsg.hideSpinner(currentRef)
                }
            }
        )
    }

    /**
     * @Description: pass obj having restaurant id and return matched resturant object according to resto id
     * 
     * @Author: Avinash Jaiswal
     * */
    getBusinessObj(obj) {
        // console.log("getBusinessObj : ", obj)
        let restoDropdown = JSON.parse(localStorage.getItem('restoDropdown'))
        // console.log("restoDropdown : ", restoDropdown)
        let matchedResto
		let isMatchFound = restoDropdown.some(function(val, i) {
            // console.log("val : ", val, " i : ", i, " obj : ", obj)
			if (val.id === obj.restaurant) {
                matchedResto = val
				return true; // this will work as break statement
			}
        });
        
        if (isMatchFound)
            return matchedResto
        return {}
    }

    /**
     * @Description: created mask for fein field which convert entered value in format XX-XXXXXXX
     * 
     * @Author: Avinash Jaiswal
     * */
    feinMask: any
    // enrollFeinMask: any
    setFeinMask(event) {
        var keys = event.charCode || event.keyCode || 0;

        if(keys !== 8 && keys !== 9){
            var keyuse
            if(keys >= 37 && keys <= 40){
                keyuse = ''
            }
            else {
                keyuse = event.key
            }

            let val = event.target.value
            let valLen = val.length
            if(valLen == 2 && keyuse !== '') {
                this.feinMask = val + '-' + keyuse
                return false
            }
            if(valLen > 10 ) {
                this.feinMask = event.target.value
                return false
            }
        }

        return (keys == 8 || 
            keys == 9 ||
            (keys >= 48 && keys <= 57) ||            
            (keys >= 96 && keys <= 105));
    }
}
