import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { LoginComponent } from './components/no-auth/login/login.component';
import { ForgotPasswordComponent } from './components/no-auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/no-auth/reset-password/reset-password.component';
import { LayoutComponent } from './components/layout/layout/layout.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { TokenInterceptor } from './shared/helpers';

import { AppCommonModule } from './shared/modules/app-common/app-common.module';
import { AgmCoreModule } from '@agm/core';
import { Constants } from './config/constants';
import { AboutUsComponent } from './components/web-static/about-us/about-us.component';
import { HomeComponent } from './components/web-static/home/home.component';
import { EnrollNowComponent } from './components/enroll-now/enroll-now.component';
import { ContactUsComponent } from './components/web-static/contact-us/contact-us.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HowWorksComponent } from './components/web-static/how-works/how-works.component';
import { WebStaticHeaderComponent } from './components/web-static/layouts/web-static-header/web-static-header.component';
import { WebStaticFooterComponent } from './components/web-static/layouts/web-static-footer/web-static-footer.component';
import { WebStaticFooterBannerComponent } from './components/web-static/layouts/web-static-footer-banner/web-static-footer-banner.component';
import { PrivacyPolicyComponent } from './components/web-static/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './components/web-static/terms-of-use/terms-of-use.component';
import { FaqComponent } from './components/web-static/faq/faq.component';
import { WhatWeOfferComponent } from './components/web-static/what-we-offer/what-we-offer.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        LayoutComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        AboutUsComponent,
        HomeComponent,
        EnrollNowComponent,
        ContactUsComponent,
        HowWorksComponent,
        WebStaticHeaderComponent,
        WebStaticFooterComponent,
        WebStaticFooterBannerComponent,
        PrivacyPolicyComponent,
        TermsOfUseComponent,
        FaqComponent,
        WhatWeOfferComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgbModule,
        // ToastrModule.forRoot(),
        ToastrModule.forRoot({
            closeButton: true,
            timeOut: 8000,
            maxOpened: 1
        }),
        AppCommonModule,
        AgmCoreModule.forRoot({
            apiKey: Constants.GOOGLE_MAP_KEY,
            libraries: ["places"]
        })
    ],
    providers: [
        Title,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }