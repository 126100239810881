import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '../models';
import { Constants } from 'src/app/config/constants';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})

export class AuthService {
    private currentUserSubject: BehaviorSubject<any>
    private currentUser: Observable<any>
    profile_image : any ;
    

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')))
        this.currentUser = this.currentUserSubject.asObservable()
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value
    }

    /**
     * @Description login in system and set current user in local storage and response to login components
     * 
     * @Params post data[object]
     * 
     * @Return response
     * 
     * @Author Avinash Jaiswal
     * */
    login(postData) {
        var url = `${Constants.API_URL}${Constants.APIS.USERACTIVITY}/`
        // console.log("authservice login")
        return this.http.post<any>(url, postData)
            .pipe(
                map(
                    user => {
                        if (user && user[Constants.DATA][Constants.TOKEN]) {
                            let userData = user[Constants.DATA]
                            if ([1,2].indexOf(userData.user_type) > -1) {
                                localStorage.setItem('currentUser', JSON.stringify(userData))
                                this.currentUserSubject.next(userData)
                                // console.log(JSON.parse(localStorage.getItem('currentUser')))
                                this.profile_image = JSON.parse(localStorage.getItem('currentUser')).profile_image
                            } else {
                                return false
                            }
                        }
                        return user
                    }
                )
            )
    }

    /**
     * @Description remove current user from local storage and redirect to next
     * 
     * @Author Avinash Jaiswal
     * */
    logout() {
        localStorage.removeItem('restaurant')
        localStorage.removeItem('currentUser')
        this.currentUserSubject.next(null)
        this.router.navigate([Constants.REDIRECT_URLS.LOGIN])
    }
}